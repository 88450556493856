<template>
  <div class="ui-product">
    <header class="ui-product__header">
      <CRow class="justify-content-between align-items-center h-100">
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <div class="ui-type-display-lg">
            {{ $t("producer.products.availabilityEdit.title") }}
          </div>
        </CCol>
        <CCol sm="auto" class="mt-1 mb-2 my-sm-0">
          <CButton color="outline-primary" class="mr-3" @click="onCancel()">{{
            $t("producer.products.availabilityEdit.btnCancel")
          }}</CButton>
          <CButton
            color="outline-primary"
            class="mr-3"
            @click="onSave(false)"
            >{{ $t("producer.products.availabilityEdit.btnSave") }}</CButton
          >
          <CButton color="primary" @click="onSave(true)">{{
            $t("producer.products.availabilityEdit.btnSaveContinue")
          }}</CButton>
        </CCol>
      </CRow>
    </header>
    <section class="ui-product__body">
      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>

      <div class="ui-product__main ui-product__main--full" v-if="initialized">
        <CCard class="card-bordered">
          <CCardBody>
            <CRow class="align-items-end">
              <CCol md="5">
                <CInput
                  :label="$t('producer.products.availabilityEdit.search.title')"
                  :placeholder="
                    $t('producer.products.availabilityEdit.search.placeholder')
                  "
                  v-model="search"
                >
                  <template #prepend-content>
                    <font-awesome-icon icon="search" />
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {{ $t("producer.products.availabilityEdit.table.name") }}
                    </th>
                    <th>
                      {{ $t("producer.products.availabilityEdit.table.qta") }}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      {{
                        $t("producer.products.availabilityEdit.table.selltime")
                      }}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="variant in searchResult" :key="variant.id">
                    <td class="ui-cell-switch">
                      <CSwitch
                        variant="3d"
                        size="sm"
                        color="secondary"
                        :checked.sync="variant.is_active"
                      />
                    </td>
                    <td>
                      <strong>{{ getProductName(variant) }}</strong>
                      <div>{{ getVariantName(variant) }}</div>
                      <div v-if="variant.for_market">
                        <i>{{ getMarketName(variant) }}</i>
                      </div>
                    </td>
                    <td class="ui-cell-qty1">
                      <CSelect
                        :value.sync="variant.availability_limitless"
                        :options="options.availability_limitless"
                        :isValid="variant.isValid('availability_limitless')"
                        :invalidFeedback="
                          variant.getErrorMessage('availability_limitless')
                        "
                        custom
                      />
                    </td>
                    <td class="ui-cell-qty2">
                      <CInput
                        v-model="variant.availability_start"
                        :append="
                          $t('producer.products.availabilityEdit.table.unit')
                        "
                        :isValid="variant.isValid('availability_start')"
                        :invalidFeedback="
                          variant.getErrorMessage('availability_start')
                        "
                      />
                    </td>
                    <td class="ui-cell-qty3">
                      <CSelect
                        :value.sync="variant.availability_xd"
                        :options="options.availability_xd"
                        :isValid="variant.isValid('availability_xd')"
                        :invalidFeedback="
                          variant.getErrorMessage('availability_xd')
                        "
                        custom
                      />
                    </td>
                    <td class="ui-cell-separator"></td>
                    <td class="ui-cell-period1">
                      <CSelect
                        :value.sync="variant.valid_limited"
                        :options="options.valid_limited"
                        :isValid="variant.isValid('valid_limited')"
                        :invalidFeedback="
                          variant.getErrorMessage('valid_limited')
                        "
                        custom
                      />
                    </td>
                    <td class="ui-cell-period2">
                      <UiDatePicker
                        :date.sync="variant.valid_from"
                        :disabled="variant.valid_limited === 'always'"
                        :isValid="variant.isValid('valid_from')"
                        :invalidFeedback="variant.getErrorMessage('valid_from')"
                      />
                    </td>
                    <td class="ui-cell-period3">
                      <UiDatePicker
                        :date.sync="variant.valid_to"
                        :disabled="variant.valid_limited === 'always'"
                        :isValid="variant.isValid('valid_to')"
                        :invalidFeedback="variant.getErrorMessage('valid_to')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </section>
  </div>
</template>

<script>
import get from "lodash/get";
import toLower from "lodash/toLower";
import pick from "lodash/pick";
import {
  getAvailabilityLimitlessOptions,
  getAvailabilityXdOptions,
  getValidLimitedOptions,
  getProducerMarkets,
} from "../../../helpers/options";

import UiDatePicker from "../../../common/form/UiDatePicker";
import { endPoint } from "../../../ds";
import ProductVariant from "../../../helpers/ProductVariant";

const restoreVariants = (variants) => {
  return variants.map((variantData) => {
    return new ProductVariant(variantData);
  });
};

export default {
  name: "ProductsTableListEditAvailability",

  components: {
    UiDatePicker,
  },

  async mounted() {
    if (this.variants.length < 1) {
      this.onCancel();
    }
    await this.init();
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("service/selectedVariants", []);
    next();
  },

  data: function () {
    return {
      initialized: false,
      loading: false,
      search: "",
      variants: restoreVariants(this.$store.state.service.variants),
      options: {
        tax: [],
        availability_limitless: getAvailabilityLimitlessOptions(this.$i18n),
        availability_xd: getAvailabilityXdOptions(this.$i18n),
        valid_limited: getValidLimitedOptions(this.$i18n),
        markets: [],
      },
    };
  },
  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
    currencySymbol: function () {
      return get(
        this.$store.state,
        "connections.current.family.wallets[0].get_currency_display_short"
      );
    },
    searchResult: function () {
      let results = this.variants;
      if (this.search !== "" && this.search) {
        const lang = this.lang;
        const search = toLower(this.search);
        results = results.filter((item) => {
          const productName = toLower(
            get(item, `productproducer.translations.${lang}.name`)
          );
          const variantName = toLower(
            get(item, `productproducer.translations.${lang}.name`)
          );

          return productName.includes(search) || variantName.includes(search);
        });
      }
      return results;
    },
  },

  methods: {
    async init() {
      this.loading = true;
      const producer_id = this.$store.state.role.producer__id;
      try {
        this.options.markets = await getProducerMarkets({ producer_id }, this);
        this.initialized = true;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    getProductName(variant) {
      return get(variant, `productproducer.translations.${this.lang}.name`);
    },

    getVariantName(variant) {
      return get(variant, `translations.${this.lang}.name`);
    },

    getMarketName(variant) {
      if (variant.for_market == null) {
        return null;
      }
      const self = this;
      const market_name = self.options.markets.reduce(function (map, obj) {
        if (obj.value == variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name;
    },

    onCancel() {
      this.$router.push({
        name: "RoleProductsList",
        params: { id: this.$store.state.role.id },
      });
    },

    onSave(continueEdit = true) {
      const self = this;
      const roleId = this.$store.state.role.id;
      const url =
        endPoint("role_producer_products_lots_NEW", [roleId]) + "multi_update/";

      this.variants.forEach((variant) => {
        variant.resetErrors();
      });

      const params = this.variants.map((variant) => {
        const data = variant.prepare(true);
        return pick(data, [
          "id",
          "is_active",
          "availability_limitless",
          "availability_start",
          "availability_xd",
          "valid_from",
          "valid_to",
        ]);
      });

      const handleRequestErrors = (error) => {
        const { fields } = error.response.data.errors;
        if (fields) {
          self.variants.forEach((variant, idx) => {
            const variantErrors = fields[idx];
            if (variantErrors !== undefined) {
              variant.setErrors(variantErrors);
            }
          });
        }
      };

      this.axios
        .patch(url, params)
        .then(() => {
          self.$store.dispatch("toaster/add", {
            title: "Aggiornamento disponibilità",
            text: "Disponibilità aggiornate con successo",
            autohide: true,
          });
          if (!continueEdit) {
            self.onCancel();
          }
        })
        .catch(handleRequestErrors);
    },
  },
};
</script>
